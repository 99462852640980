.print--footer
  display: none

@media print

  @page
    margin: 1.5cm 1cm 1.5cm
    size: auto
    padding: 0 0 4cm !important

  #content-inner
    // leave width as is so the view stays the same, but shrinks down to the page width
    // width: 100%
    padding: 0

  .header
    display: none

  #content
    padding: 0

  .announcement
    display: none

  #globalAjax
    display: none !important

  #globalMessages
    display: none

  .tooltiptext
    display: none

  #footer
    display: none

  .print--footer
    display: flex
    position: fixed
    flex-direction: row
    align-items: flex-end
    font-size: pxToRem(12)
    bottom: 0
    padding: 0
    width: 100%

    > *
      display: flex

  .print--username
    padding-right: pxToRem(10)

  .print--logo
    flex-grow: 1

  // couldn't make the footer
  .print--footer
    display: none

  .eln
    @import eln-print

    .print-preview__toolbar
      display: none

.eln
  @import paper-sizes

  .print-preview
    @import eln-print
